.App {
  text-align: center;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  /* width: 80%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  /* margin: 0 auto; */
  /* background-color: #d8d8d8; */
}

.content > div {
  width: 100%;
  padding: 15px 10%;
}

#content_title {
  display: none;
}

@media screen and (max-width: 780px) {

  #content_title {
    display: block;
    /* border-bottom: 1px solid #003658; */
  }
  
  #content_title::after {

    background: #003658 none repeat scroll 0 0;
    content: "";
    display: block;
    height: 1px;
    margin: 1.5rem auto 0;
    position: relative;
    width: 100px;
    box-shadow: 0 0 5px rgb(0, 0, 0, .5);

}

  .Header-title {
    display: none;
  }

}