.App-header {
    background-color: #003658;
    max-height: 120px;
    color: white;
    display: flex;
    align-items: center;
}
.Header-logo {
    height: auto;
    width: 100%;
}
.Header-logo-holder {
    height: 100%;
    max-width: 150px;
    padding: 10px;
    cursor: pointer;
}
.Header-title {
    font-size: 2rem;
    line-height: 1.2;
}

#Header-menu {
    margin-left: auto;
}

.App-header .ui.secondary.pointing.menu .item,
.App-header .ui.secondary.pointing.menu .item:hover {
    color: white;
}

.App-header .ui.secondary.pointing.menu .active.item,
.App-header .ui.secondary.pointing.menu .active.item:hover {
    border-color: white;
    color: white;
}

#Header-dropdown {
    display: none;
    margin-left: auto;
    margin-right: 2%;
}

@media screen and (max-width: 530px) {

    #Header-dropdown {
        display: block;
    }

    #Header-menu {
        display: none;
    }

    .Header-logo-holder {
        max-width: 100px;
        padding: 5px;
    }
  
  }