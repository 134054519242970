@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,700);
.App {
  text-align: center;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  /* width: 80%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  /* margin: 0 auto; */
  /* background-color: #d8d8d8; */
}

.content > div {
  width: 100%;
  padding: 15px 10%;
}

#content_title {
  display: none;
}

@media screen and (max-width: 780px) {

  #content_title {
    display: block;
    /* border-bottom: 1px solid #003658; */
  }
  
  #content_title::after {

    background: #003658 none repeat scroll 0 0;
    content: "";
    display: block;
    height: 1px;
    margin: 1.5rem auto 0;
    position: relative;
    width: 100px;
    box-shadow: 0 0 5px rgb(0, 0, 0, .5);

}

  .Header-title {
    display: none;
  }

}
.App-header {
    background-color: #003658;
    max-height: 120px;
    color: white;
    display: flex;
    align-items: center;
}
.Header-logo {
    height: auto;
    width: 100%;
}
.Header-logo-holder {
    height: 100%;
    max-width: 150px;
    padding: 10px;
    cursor: pointer;
}
.Header-title {
    font-size: 2rem;
    line-height: 1.2;
}

#Header-menu {
    margin-left: auto;
}

.App-header .ui.secondary.pointing.menu .item,
.App-header .ui.secondary.pointing.menu .item:hover {
    color: white;
}

.App-header .ui.secondary.pointing.menu .active.item,
.App-header .ui.secondary.pointing.menu .active.item:hover {
    border-color: white;
    color: white;
}

#Header-dropdown {
    display: none;
    margin-left: auto;
    margin-right: 2%;
}

@media screen and (max-width: 530px) {

    #Header-dropdown {
        display: block;
    }

    #Header-menu {
        display: none;
    }

    .Header-logo-holder {
        max-width: 100px;
        padding: 5px;
    }
  
  }
table {
    border-collapse: collapse;
}

.ui.table thead th {
    border: 1px solid black;
    padding: .78571429em .5em !important;
}

#scorecard {
    background-color: #e5e5e5;
}

#scorecard .tableHolder {
    margin: 0px -10%;
    overflow-y: hidden;
    overflow-x: auto;
}

#scorecard table {
    overflow-y: scroll;
    width: 710px;
    margin: 0 auto;
    text-align: center;
}

#scorecard td {
    background-color: white;
}

#scorecard td:nth-child(5),
#scorecard th
{
    background-color: #003658;
    color: white;
}



@media screen and (max-width: 40em) {
    .ui.table thead th, .ui.table tbody td {
        padding: .15em .5em !important;
    }
/*
    #scorecard td:nth-child(5) {
        background-color: white;
        color: inherit;
    }

    .responsiveTable td.pivoted {
        padding-left: unset !important;
        display: flex;
    }

    .responsiveTable td .tdBefore {
        position: static !important;
        white-space: normal !important;
        width: 65% !important;
    }
*/
}
.percentInputHolder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.percentInputHolder > .ui.input {
    width: 100px !important;
}

.percentInputHolder > .ui.input > input {
    text-align: right;
}

.percentsHolder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.ui.form .percentsHolder > .field {
    margin: 0 10px 1em !important;
}

.requiredstar::after {

    margin: -.2em 0 0 .2em;
    content: '*';
    color: #db2828;
    display: inline-block;
    vertical-align: top;
}
#survey {
    background-color: #e5e5e5;
}
.App-footer {
    background-color: #003658;
    min-height: 120px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Footer-logo-holder {
    height: 100%;
    max-width: 200px;
}

.Footer-logo {
    height: auto;
    width: 100%;
}

.twd-link a {
    color: #c4e2ff;
}

.twd-link a:hover,
.twd-link a:active {
    color: #2a8ff0;
}
.chartsEmbedHolder
{
    width: 100%;
    margin-top: 10px;
    /* height: 100%;
    margin: 0px -10%;
    overflow-y: hidden;
    overflow-x: auto; */
}
.fred-embed-container
{
    overflow-y: hidden;
    overflow-x: auto;
}

.fred-embed-container iframe  {
    width: 919px;
    height: 515px;
    /* display: none; */
}

#analytics 
{
    padding: 15px 2%;
    width: 100%;
}

.fred-image-container,
.fred-image-container img
{
    width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web', sans-serif;
  background-color: #fbfbfb;
}

h2.ui.header {
  color: #0e4c7d;
}

.customRadioGroup {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.customRadioGroup > .field {
  padding: 0 .5em;
}

html,
body,
#root
{
  height: 100%;
}
