table {
    border-collapse: collapse;
}

.ui.table thead th {
    border: 1px solid black;
    padding: .78571429em .5em !important;
}

#scorecard {
    background-color: #e5e5e5;
}

#scorecard .tableHolder {
    margin: 0px -10%;
    overflow-y: hidden;
    overflow-x: auto;
}

#scorecard table {
    overflow-y: scroll;
    width: 710px;
    margin: 0 auto;
    text-align: center;
}

#scorecard td {
    background-color: white;
}

#scorecard td:nth-child(5),
#scorecard th
{
    background-color: #003658;
    color: white;
}



@media screen and (max-width: 40em) {
    .ui.table thead th, .ui.table tbody td {
        padding: .15em .5em !important;
    }
/*
    #scorecard td:nth-child(5) {
        background-color: white;
        color: inherit;
    }

    .responsiveTable td.pivoted {
        padding-left: unset !important;
        display: flex;
    }

    .responsiveTable td .tdBefore {
        position: static !important;
        white-space: normal !important;
        width: 65% !important;
    }
*/
}