.App-footer {
    background-color: #003658;
    min-height: 120px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Footer-logo-holder {
    height: 100%;
    max-width: 200px;
}

.Footer-logo {
    height: auto;
    width: 100%;
}

.twd-link a {
    color: #c4e2ff;
}

.twd-link a:hover,
.twd-link a:active {
    color: #2a8ff0;
}