@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,700');
body {
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web', sans-serif;
  background-color: #fbfbfb;
}

h2.ui.header {
  color: #0e4c7d;
}

.customRadioGroup {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.customRadioGroup > .field {
  padding: 0 .5em;
}

html,
body,
#root
{
  height: 100%;
}