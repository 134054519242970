.chartsEmbedHolder
{
    width: 100%;
    margin-top: 10px;
    /* height: 100%;
    margin: 0px -10%;
    overflow-y: hidden;
    overflow-x: auto; */
}
.fred-embed-container
{
    overflow-y: hidden;
    overflow-x: auto;
}

.fred-embed-container iframe  {
    width: 919px;
    height: 515px;
    /* display: none; */
}

#analytics 
{
    padding: 15px 2%;
    width: 100%;
}

.fred-image-container,
.fred-image-container img
{
    width: 100%;
}