.percentInputHolder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.percentInputHolder > .ui.input {
    width: 100px !important;
}

.percentInputHolder > .ui.input > input {
    text-align: right;
}

.percentsHolder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.ui.form .percentsHolder > .field {
    margin: 0 10px 1em !important;
}

.requiredstar::after {

    margin: -.2em 0 0 .2em;
    content: '*';
    color: #db2828;
    display: inline-block;
    vertical-align: top;
}